import {
  ApolloError,
  ApolloQueryResult,
  NetworkStatus,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  useQuery,
} from "@apollo/client";
import { DocumentNode } from "graphql";
import gql from "graphql-tag";

import {
  attributeFragment,
  basicProductFragment,
  featuredProductsFragment,
  menuItemFragment,
  productPricingFragment,
  shippingMethodsFragment,
} from "./fragments";

type LoadMore<TData> = (
  mergeFn: (prev: TData, next: TData) => TData,
  endCursor: string
) => Promise<ApolloQueryResult<TData>>;

export const useTypedQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> & {
  loadMore: LoadMore<TData>;
} => {
  // Default SSR to on
  const fetchOptions = options
    ? {
        ...options,
        ssr: true,
      }
    : options;
  const queryResult = useQuery<TData, TVariables>(query, {
    ...fetchOptions,
    notifyOnNetworkStatusChange: true,
  });

  const loadMore: LoadMore<TData> = async (mergeFn, endCursor) => {
    let result: ApolloQueryResult<TData> = {
      data: queryResult.data!,
      loading: false,
      error: new ApolloError({ networkError: Error("Failed to fetch") }),
      networkStatus: NetworkStatus.error,
    };
    try {
      result = await queryResult.fetchMore({
        query,
        updateQuery: (previousResults, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResults;
          }
          return mergeFn(previousResults, fetchMoreResult);
        },
        variables: { ...options?.variables, after: endCursor },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn("Could not load more data. Check network connection.");
    }
    return result;
  };

  return { loadMore, ...queryResult };
};

export const featuredProductsQuery = gql`
  ${featuredProductsFragment}
  query FeaturedProductsQuery($channel: String!) {
    ...FeaturedProducts
  }
`;

export const categoryProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${shippingMethodsFragment}
  query CategoryProductsQuery(
    $channel: String!
    $after: String
    $filters: ProductFilterInput!
    $first: Int!
    $sortBy: ProductOrder
  ) {
    products(
      first: $first
      public: true
      channel: $channel
      filter: $filters
      after: $after
      sortBy: $sortBy
    ) {
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingFieldSF
          ...ShippingMethods
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const wishlistProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  query WishlistProductsQuery(
    $channel: String!
    $first: Int!
    $wishlist: Boolean!
  ) {
    products(
      first: $first
      public: true
      channel: $channel
      wishlist: $wishlist
    ) {
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingFieldSF
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const lenderProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${shippingMethodsFragment}
  query LenderProductsQuery($channel: String!, $lenderSlug: String!) {
    products(
      first: 20
      public: true
      channel: $channel
      filter: { lenderSlug: $lenderSlug }
    ) {
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingFieldSF
          ...ShippingMethods
        }
      }
      totalCount
    }
  }
`;

export const lenderDetailsQuery = gql`
  query LenderDetailsQuery($slug: String!) {
    lender(slug: $slug) {
      id
      name
      rating
      avatar {
        url
      }
      user {
        id
      }
      isActiveCloset
      lenderOrdersMade(status: [FULFILLED, ARCHIVED])
      lenderAggregateRating {
        ratingValue
        ratingCount
      }
    }
  }
`;

export const attributeValuesQuery = gql`
  query AttributeValuesQuery($attribute: String!) {
    attributes(
      first: 100
      filter: { search: $attribute, visibleInStorefront: true }
    ) {
      edges {
        node {
          id
          name
          values(filterSoftDeleted: true) {
            id
            name
            slug
          }
        }
      }
    }
  }
`;

export const attributedProductsQuery = gql`
  ${basicProductFragment}
  ${productPricingFragment}
  ${shippingMethodsFragment}
  query AttributedProductsQuery(
    $channel: String!
    $attributes: [AttributeInput!]
    $location: [PostCodeFilterInput!]
    $price: PriceRangeInput!
  ) {
    products(
      first: 20
      public: true
      channel: $channel
      filter: {
        channel: $channel
        attributes: $attributes
        postcodes: $location
        price: $price
      }
    ) {
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingFieldSF
          ...ShippingMethods
          category {
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const shopAttributesQuery = gql`
  ${attributeFragment}
  query ShopAttributesQuery(
    $channel: String!
    $collectionId: ID
    $categoryId: ID
  ) {
    attributes(
      filter: {
        channel: $channel
        inCollection: $collectionId
        inCategory: $categoryId
        filterableInStorefront: true
      }
      first: 100
    ) {
      edges {
        node {
          ...Attribute
        }
      }
    }
  }
`;

export const shopMenusQuery = gql`
  ${menuItemFragment}
  query ShopMenusQuery(
    $channel: String!
    $footerSlug: String!
    $mainMenuSlug: String!
  ) {
    shop {
      name
      marketplace
      language
      domain {
        url
        host
      }
    }
    footer: menu(channel: $channel, slug: $footerSlug) {
      id
      items {
        ...MenuItem
        children {
          ...MenuItem
        }
      }
    }
    mainMenu: menu(channel: $channel, slug: $mainMenuSlug) {
      items {
        ...MenuItem
        children {
          ...MenuItem
          children {
            ...MenuItem
          }
        }
      }
    }
  }
`;
